.introductionPack {
    font-size: 0;
    overflow: hidden;
    
    ul {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 84px;
        
        li {
            width: 195px;
            position: relative;
            cursor: pointer;
            
            img:first-child {
                width: 100%;
                display: block;
            }
            
            img:last-child {
                width: 100%;
                position: absolute;
                top: 30%;
                left: 0;
                opacity: 0;
                transform: translateY(100%);
                transition: all 0.3s ease-in-out;
                pointer-events: none;
            }
            
            // PC端hover效果
            @media (hover: hover) {
                &:hover img:last-child {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            
            &.showTag img:last-child {
                opacity: 1;
                transform: translateY(0);
            }
            
            // 前三个元素的点击效果
            &:nth-child(-n+3) {
                &:active {
                    transform: scale(0.98);
                    transition: transform 0.2s ease;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .introductionPack {
        ul {
            width: 100%;
            margin-bottom: 0;
            overflow-x: auto;
            display: -webkit-box;
            -webkit-overflow-scrolling: touch;
            padding: 0 0.12rem;
            scrollbar-width: none;
            -ms-overflow-style: none;
            
            &::-webkit-scrollbar {
                display: none;
            }
            
            li {
                width: 1.9rem;
                margin: 0 0.12rem;
                position: relative;
                flex-shrink: 0;
                
                img:first-child {
                    width: 100%;
                }
                
                img:last-child {
                    width: 100%;
                }
            }
        }
    }
}