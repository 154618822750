
    .scrollingLayer{
        height: calc(100vh);
        overflow: auto;
        img{
            width: auto;
            height: auto;
        }
        .contactDetails{
            display: flex;
            flex-direction: column;
            align-items: center;
            position: fixed;
            top: 400px;
            right: 40px;
            z-index: 9999;
            font-size: 0;
            li{
                padding-left: 5px;
                margin-bottom: 20px;
                position: relative;
                img{
                    width: 40px;
                }
                .hovPack{
                    position: absolute;
                    left: -155px;
                    top: 0;
                    width: 155px;
                    background: #fff;
                    display: none;
                    div{
                        height: 40px;
                        line-height: 40px;
                        color: #A647EA;
                        font-size: 16px;
                        text-align: center;
                    }
                    div:hover{
                        background: var(--Linear, linear-gradient(90deg, #A647EA 0%, #BD75F3 43.5%, #DAB0FF 100%));
                        color: #fff;
                    }
                }
            }
            li:hover .hovPack{
                display: block;
            }
        }
        .pagePack1{
            width: 100%;
            min-width: 1440px;
            position: relative;
            // overflow: hidden;
            font-size: 0;
            view-timeline: --sectionReveal;
            img{
                width: 100%;
            }
            .homeBg{
                width: 100%;
            }
            .equipmentPack{
                cursor: pointer;
                position: fixed;
                top: 252px;
                right: 27px;
                z-index: 999;
                animation-timeline: --sectionReveal;
                animation-name: slideInRight;
                animation-range: entry 0% entry 40%;
                animation-fill-mode: both;
                img{
                    animation: scaleAnimation 2.5s infinite linear;
                    width: 207px;
                }
            }
            .countdownPack{
                width: 743px;
                height: 148px;
                position: absolute;
                left: 50%;
                bottom: 150px;
                margin-left: -367.5px;
                font-size: 24px;
                background-image: url(~/public/img/countdownBg.png);
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center top;
                display: flex;
                flex-direction: column;
                align-items: center;
                // justify-content: center;
                
                
                h2{
                    font-size: 16px;
                    font-weight: 900;
                    color: #BD75F4;
                    margin-top: 16px;
                    // background: var(--Linear, linear-gradient(90deg, #6B93F2 20%, #9477EE 30%, #ED8CDD 100%));
                    // background-clip: text;
                    // -webkit-background-clip: text;
                    // -webkit-text-fill-color: transparent;
                    // text-overflow: ellipsis; 
                }
                .countdown{
                    margin-top: 11px;
                    font-size: 48px;
                    height: 40px;
                    line-height: 40px;
                    font-weight: 900;
                    vertical-align: bottom;
                    color: #894BE6;
                    // background: var(--Linear, linear-gradient(90deg, #6B93F2 20%, #9477EE 30%, #ED8CDD 100%));
                    // background-clip: text;
                    // -webkit-background-clip: text;
                    // -webkit-text-fill-color: transparent;
                    // text-overflow: ellipsis;
                    margin-bottom: 8px;
                    em{
                        font-size: 24px;
                        margin: 0 10px;
                    }
                }
                .title{
                    width: 567px;
                    height: 40px;
                    color: #fff;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                    font-weight: 900;
                    text-overflow: ellipsis;
                }
            }
            .swiperPc {
                display: block;
            }
            .swiperH5 {
                display: none;
            }
        }
        .systemBg{
            display: none;
        }
        .pagePack2{
            background-image: url(~/public/img/homeBg.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center bottom -60px;
            position: relative;
            padding-bottom: 120px;
            .titlePack{
                height: 200px;
                img{
                    height: 100%;
                }
            }
            .contentPack{
                width: 1440px;
                background: #fff;
                overflow: hidden;
                margin: 0 auto;
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
            }
            .contentPack1{
                border-radius: 60px 60px 0 0;
                margin-top: -120px;
                view-timeline: --sectionReveal;
                .introductionPack{
                    margin-top: 73px;
                    background-image: url(~/public/img/introductionBg.png);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center bottom;
                    padding: 0 60px;
                    font-size: 0;
                    .titlePack{
                        font-size: 0;
                        margin-bottom: -30px;
                        animation-timeline: --sectionReveal;
                        animation-name: slideInLeft;
                        animation-range: entry 0% entry 60%;
                        animation-fill-mode: both;
                    }
                    .pack{
                        animation-timeline: --sectionReveal;
                        animation-name: slideInRight;
                        animation-range: entry 0% entry 80%;
                        animation-fill-mode: both;
                    }
                }
            }
            .contentPack2{
                view-timeline: --sectionReveal;
                .swiper{
                    background-image: url(~/public/img/swiperBg.png);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center bottom;
                    .titlePack{
                        float: right;
                        font-size: 0;
                        margin: 67px 180px -30px 0;
                        animation-timeline: --sectionReveal;
                        animation-name: slideInRight;
                        animation-range: entry 0% entry 80%;
                        animation-fill-mode: both;
                    }
                    .pack{
                        animation-timeline: --sectionReveal;
                        animation-name: slideInLeft;
                        animation-range: entry 0% entry 80%;
                        animation-fill-mode: both;
                    }
                }
            }
            .contentPack3{
                view-timeline: --sectionReveal;
                .announcement{
                    margin-top: 40px;
                    font-size: 0;
                    
                    .titlePack{
                        margin-left: 157px;
                        margin-bottom: -30px;
                        animation-timeline: --sectionReveal;
                        animation-name: slideInLeft;
                        animation-range: entry 0% entry 80%;
                        animation-fill-mode: both;
                    }
                    .announcementPack{
                        background-image: url(~/public/img/announcementBg.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                        background-position: center bottom;
                        padding-bottom: 120px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .announcementL{
                            width: 380px;
                            height: 463px;
                            margin-right: 20px;
                            background: linear-gradient(0deg, #ECF1FF 0%, #9E55D2 100%);
                            box-shadow: 0px 0px 6px 0px #FFF inset, 0px 0px 12px 0px rgba(70, 28, 175, 0.20);
                            text-align: center;
                            animation-timeline: --sectionReveal;
                            animation-name: slideInLeft;
                            animation-range: entry 0% entry 80%;
                            animation-fill-mode: both;
                            .titleImg{
                                width: 241px;
                                margin: 40px 0 20px 0;
                            }
                            .titleList{
                                text-align: left;
                                width: 100%;
                                height: 100%;
                                padding: 20px 30px;
                                overflow: auto;
                                border-radius: 40px;
                                li{
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    border-radius: 60px;
                                    border: 1px solid #FFF;
                                    background: rgba(56, 78, 158, 0.25);
                                    padding: 12px 20px;
                                    margin-bottom: 8px;
                                    img{
                                        width: 20px;
                                    }
                                    div{
                                        width: 215px;
                                        font-size: 14px;
                                        color: #fff;
                                        .time{

                                        }
                                        .content{
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                    }
                                }
                            }
                        }
                        .announcementR{
                            width: 780px;
                            height: 463px;
                            background-image: url(~/public/img/videoBg.png);
                            background-size: 100%;
                            background-repeat: no-repeat;
                            background-position: center bottom;
                            box-shadow: 0px 0px 20px 0px rgba(110, 148, 245, 0.24);
                            animation-timeline: --sectionReveal;
                            animation-name: slideInRight;
                            animation-range: entry 0% entry 80%;
                            animation-fill-mode: both;
                            .video{
                                width: 728px;
                                height: 411px;
                                margin: 26px;
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }
            .contentPack4{
                border-radius: 0 0 60px 60px;
                view-timeline: --sectionReveal;
                .footer{
                    background-image: url(~/public/img/footerBg.png);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center bottom;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 640px;
                    margin-top: 50px;
                    padding-bottom: 50px;
                    .footerL{
                        width: 526px;
                        font-size: 0;
                        animation-timeline: --sectionReveal;
                        animation-name: slideInLeft;
                        animation-range: entry 0% entry 70%;
                        animation-fill-mode: both;
                        img{
                            width: 100%;
                        };
                    }
                    .footerR{
                        font-size: 16px;
                        margin-left: 120px;
                        color: #C483F6;
                        animation-timeline: --sectionReveal;
                        animation-name: slideInRight;
                        animation-range: entry 0% entry 70%;
                        animation-fill-mode: both;
                        
                        .textPack{
                            line-height: 34px;
                            font-weight: 700;
                            display: flex;
                            align-items: center;
                            div{
                                height: 18px;
                                width: 1px;
                                background: #C483F6;
                                margin: 0 28px;
                            }
                            span{
                                display: flex;
                                align-items: center;
                                img{
                                    width: 24px;
                                    margin-right: 4px;
                                }
                            }
                            .androdPack{
                                margin-right: 28px;
                            }
                        }
                        p{
                            font-size: 12px;
                            line-height: 22px;
                            font-weight: 400;
                            margin-top: 6px;
                        }
                    }
                }
            }
        }
    }
    @keyframes slideInLeft {
        from {
          transform: translateX(-300px);
          opacity: 0;
        }
        to {
          transform: translateX(0);
          opacity: 1;
        }
    }
    @keyframes slideInRight {
        from {
          transform: translateX(300px);
          opacity: 0;
        }
        to {
          transform: translateX(0);
          opacity: 1;
        }
    }

    @keyframes scaleAnimation {
        0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
          100% {
            transform: translateY(0);
          }
    }

@media (max-width: 600px) {
    .bgPack{
        background-size: cover;
        background-position: center top;
    }
    .scrollingLayer{
        height: calc(100vh - 0.6rem);
        .contactDetails{
            display: none;
        }
        .pagePack1{
            padding-top: 0.6rem;
            // background-size: cover;
            // background-image: url(~/public/img/homeBgIndexH5.png);
            min-width: auto;
            .countdownPack{
                background-image: url(~/public/img/countdownBgH5.png);
                width: 100%;
                height: 1.24rem;
                margin: 0;
                left: 0;
                bottom: 0;
                z-index: 999;
                h2{
                    font-size: 0.12rem;
                    margin-top: 0.08rem;
                }
                .countdown{
                    font-size: 0.33rem;
                    height: 0.28rem;
                    line-height: 0.2rem;
                    margin-top: 0.12rem;
                    margin-bottom: 0.14rem;
                    em{
                        font-size: 0.2rem;
                    }
                }
                .title{
                    width: 65%;
                    height: 0.32rem;
                    font-size: 0.14rem;
                    text-shadow: 0px 0.02rem 0.02rem rgba(0, 0, 0, 0.25);
                    text-align: center;
                }
                
                
            }
            .equipmentPack{
                display: none;
                position: absolute;
                top: auto;
                bottom: 0.1rem;
                right: 0.04rem;
                img{
                    width: 1.8rem;
                }
            }
            .swiperPc {
                display: none;
            }
            .swiperH5 {
                display: block;
            }
        }
        .systemBg{
            display: block;
            width: 100%;
        }
        .pagePack2{
            padding-bottom: 0;
            .titlePack{
                height: 1rem;
                img{
                    height: 100%;
                }
            }
            .contentPack{
                border-radius: 0;
                width:100%;
                margin-bottom: 0;
                margin-top: 0;
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
                .introductionPack{
                    .titlePack{
                        margin-left: 0.4rem;
                    }
                    background-image: url(~/public/img/introductionBgH5.png);
                    background-position: center top;
                    padding: 0.17rem 0 0 0;
                    margin-top: 0;
                }
                .announcement{
                    margin-top: 0.2rem;
                    .titlePack{
                        margin-left: 0.4rem;
                    }
                    .announcementPack{
                        padding-bottom: 0;
                        flex-direction: column-reverse;
                        background-image: url(~/public/img/announcementBgH5.png);
                        background-position: center top;
                        .announcementL{
                            width: 100%;
                            height: 4.26rem;
                            margin-right: 0;
                            .titleImg{
                                width: 1.57rem;
                                margin: 0.2rem 0 0.12rem 0;
                                
                            }
                            .titleList{
                                padding: 0.12rem 0.22rem;
                                height: auto;
                                li{
                                    margin-bottom: 0.08rem;
                                }
                            }
                        }
                        .announcementR{
                            width: auto;
                            height: auto;
                            margin: 0.21rem auto 1rem auto;
                            .video{
                                width: 3.27rem;
                                height: auto;
                                margin: 0.14rem 0.16rem;
                            }
                        }
                    }
                }
                .swiper{
                    .titlePack{
                        margin-left: 0.4rem;
                        margin: 0.6rem 0 0 0.4rem;
                        float: left;
                    }
                    background-image: url(~/public/img/swiperBgH5.png);
                }
                .footer{
                    flex-direction: column;
                    background-image: url(~/public/img/footerBgH5.png);
                    background-position: center bottom -300px;
                    
                    .footerL{
                        width: 2.91rem;
                        margin-bottom: 0.3rem;
                    }
                    .footerR{
                        margin-left: 0;
                    }
                }
            }
        }
    }
    // .pagePack{
    //     min-height: calc(100vh - 1rem);
    //     min-width: 100%;
    // }
    .pagePack1,.pagePack2{
        padding-bottom: 0;
    }
}