.swiperPack{
    height: 100%;
    width: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    .topTitleImg{
        width: 20%;
        margin-bottom: 4vh;
    }
    .swiperSlideshow{
        width: 1080px;
        margin-bottom: 80px;
        position: relative;
        .swiperFrame{
            padding: 8px;
            // border: 1.5px solid var(--Stroke-soil, #DFC391);
            border-radius: 12px;
            .swiperX{
                border-radius: 12px;
                overflow: hidden;
                :global {

                }
                .swiper-slide-active{
                    transform: scale(2);
                }
                img{
                    width: 100%;
                    height: 100%;
                }
                .swiperImgH5{
                    display: none;
                }
                .gradient{
                    width: 100%;
                    height: 100px;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 200px;
                    z-index: 99;
                }
                .infoPack{
                    width: 64%;
                    position: absolute;
                    right: 0;
                    top: 340px;
                    z-index: 99;
                    .infoBorder{
                        border-radius: 20px;
                        background: #FFFEFE;
                        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
                        padding: 20px;
                        text-align: center;
                        .name{
                            line-height: 40px;
                            font-size: 40px;
                            color: #8161E3;
                            img{
                                width: 48px;
                                margin: 0 20px;
                            }
                            .iconR{
                                transform: rotateY(180deg);
                            }
                        }
                        .actor{
                            line-height: 20px;
                            font-size: 20px;
                            margin: 20px 0 10px 0;
                            color: #6E94F5;
                        }
                    }
                }
                .videoPack{
                    width: 395px;
                    position: absolute;
                    left: 0;
                    top: -185px;
                    z-index: 99;
                    font-size: 0;
                    border-radius: 20px;
                    border: 3px solid var(--Linear, #FFFCE4);
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
                    overflow: hidden;
                    .video{
                        width: 100%;
                    }
                }
                .figurePack{
                    position: absolute;
                    right: 18px;
                    bottom: 12px;
                    // width: 274px;
                    height: 430px;
                    z-index: 99;
                    font-size: 0;
                    img{
                        width: auto;
                        height: 100%;
                    }
                }
            }
        }
        .swiperButtonPrevious,.swiperButtonNext{
            width: 40px;
            height: 48px;
            background-image: url('/img/path-to-left-arrow.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center top;
            position: absolute;
            top:  calc(50% - 20px);
            cursor: pointer;
        }
        .swiperButtonPrevious{
            left: -80px;
        }
        .swiperButtonNext{
            background-image: url('/img/path-to-r-arrow.png');
            right: -80px;
        }
        .swiperButtonPreviousX,.swiperButtonNextX{
            opacity: 0.3!important;
            cursor: not-allowed;
        }
    }
}

@media (max-width: 600px) {
    .swiperPack{
        .topTitleImg{
            width: 2.8rem;
            margin-bottom: 0.4rem;
        }
        .swiperSlideshow{
            width: 3.68rem;
            margin: 0 auto;
            padding-bottom: 0;
            .swiperFrame{
                padding: 0.08rem;
                width: 3.4rem;
                margin: 0 auto;
                .swiperX{
                    margin-top: 4.2rem;
                    border-radius: 0.12rem;
                    .swiperImgPc{
                        display: none;
                    }
                    .swiperImgH5{
                        display: block;
                    }
                    .gradient{
                        bottom: 0.5rem;
                    }
                    .infoPack{
                        width: 1.94rem;
                        border-radius: 0.12rem;
                        right: 0;
                        bottom: 0.5rem;
                        top: auto;
                        padding: 0.06rem;
                        .infoBorder{
                            border-radius: 0.06rem;
                            background: #FFFEFE;
                            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
                            padding: 0.08rem;
                            text-align: center;
                            .name{
                                line-height: 0.2rem;
                                font-size: 0.12rem;
                                color: #8161E3;
                                img{
                                    width: 0.13rem;
                                    margin: 0 0.06rem;
                                }
                                .iconR{
                                    transform: rotateY(180deg);
                                }
                            }
                            .actor{
                                line-height: 0.06rem;
                                font-size: 0.06rem;
                                margin: 0.05rem 0 0.07rem 0;
                            }
                        }
                    }
                    .videoPack{
                        width: 2.62rem;
                        top: 0;
                        left: 50%;
                        margin-left: -1.31rem;
                    }
                    .figurePack{
                        display: none;
                    }
                }
            }
            
            .swiperButtonPrevious,.swiperButtonNext{
                width: 0.4rem;
                height: 0.48rem;
                top:  2rem;
            }
            .swiperButtonPrevious{
                left:0;
                z-index: 99;
            }
            .swiperButtonNext{
                right: 0;
                z-index: 99;
            }
        }
    }
}