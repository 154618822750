.Pack{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: rgba(255, 255, 255, 0.60);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #707894;
    z-index: 999999;
    .popUps{
        width: 640px;
        border-radius: 10px;
        background-image: linear-gradient(#fff, #fff),
        linear-gradient(to right, #6B93F2, #ED8CDD);
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 6px 6px 0px 0px #6844CC;
        border-radius: 40px;
        padding: 5px;
        text-align: center;
        position: relative;
        .closurePack{
            position: absolute;
            right: 28px;
            top: 28px;
        }
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        ::-webkit-scrollbar-track {
            border-radius: 3px;
            background: rgba(0,0,0,0.06);
            -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.08);
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background: #EAF0FF;
            -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.2);
        }
        .popUpsHeart{
            padding: 40px 60px;
            max-height: 700px;
            overflow-x: auto;
            .logoImg{
                width: 390px;
                margin: 20px 0 50px 0;
            }
            .test1Pack{
                width: 100%;
                .text{
                    margin-bottom: 40px;
                    p{
                        color: #707894;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
            .test2Pack{
                .topicPack{
                    .title{
                        font-size: 20px;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #A647EA;
                        margin-bottom: 20px;
                        img{
                            width: 194px;
                        }
                        em{
                            margin: 0 12px;
                        }
                    }
                    .topicPack{
                        overflow: hidden;
                        .topic{
                            text-align: left;
                            border-radius: 0px 20px 20px 20px;
                            border: 2px solid #D7E1FF;
                            padding: 12px 20px;
                            background: #FFF;
                            float: left;
                            margin-bottom: 20px;
                        }
                    }
                    ul{
                        li{
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            border-radius: 40px;
                            border: 2px solid #DAB0FF;
                            padding: 8px;
                            margin-bottom: 8px;
                            text-align: left;
                            color: #A647EA;
                            .sequence{
                                width: 24px;
                                height: 24px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 99px;
                                border: 1px solid #EEDBFF;
                                margin-right: 8px;
                                color: #EEDBFF;
                            }
                        }
                    }
                    .inputPack{
                        position: relative;
                        margin-bottom: 20px;
                        .buttonPack{
                            position: absolute;
                            right: 15px;
                            top: 8px;
                        }
                    }
                    .answerPack{
                        overflow: hidden;
                        .answer{
                            text-align: left;
                            border-radius: 20px 0px 20px 20px;
                            padding: 12px 20px;
                            border: 2px solid #D7E1FF;
                            background: #A647EA;
                            float: right;
                            margin-bottom: 20px;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 600px) {
    .Pack{
        .popUps{
            width: 3.44rem;
            box-shadow: 0.06rem 0.06rem 0 0 #6844CC;
            border-radius: 0.4rem;
            padding: 0.05rem;
            .popUpsHeart{
                padding: 0.4rem 0.2rem;
                max-height: 5.5rem;
                max-height: 5.5rem;
                .logoImg{
                    width: 2.24rem;
                    margin: 0.2rem 0 0.3rem 0;
                }
                .test1Pack{
                    width: 100%;
                    .text{
                        margin-bottom: 0.4rem;
                        p{
                            color: #707894;
                            font-size: 0.14rem;
                            line-height: 0.24rem;
                        }
                    }
                }
                .test2Pack{
                    .topicPack{
                        .title{
                            font-size: 0.2rem;
                            line-height: 0.2rem;
                            margin: 0.2rem 0 0.2rem 0;
                            img{
                                width: 0.9rem;
                            }
                            em{
                                margin: 0 0.12rem;
                                font-size: 0.14rem;
                            }
                        }
                        .topicPack{
                            .topic{
                                border-radius: 0px 0.2rem 0.2rem 0.2rem;
                                border: 0.02rem solid #D7E1FF;
                                padding: 0.11rem 0.2rem;
                                margin-bottom: 0.2rem;
                                font-size: 0.15rem;
                            }
                        }
                        ul{
                            li{
                                border-radius: 0.4rem;
                                border: 0.02rem solid #DAB0FF;
                                padding: 0.08rem;
                                margin-bottom: 0.08rem;
                                font-size: 0.14rem;
                                height: 0.56rem;
                                .sequence{
                                    width: 0.24rem;
                                    min-width: 0.24rem;
                                    height: 0.24rem;
                                    border: 0.01rem solid #DAB0FF;
                                    margin-right: 0.08rem;
                                }
                            }
                        }
                        .inputPack{
                            margin-bottom: 0.2rem;
                            .buttonPack{
                                right: 0.15rem;
                                top: 0.08rem;
                            }
                        }
                        .answerPack{
                            margin-bottom: 0.2rem;
                            .answer{
                                text-align: left;
                                border-radius: 0.2rem 0 0.2rem 0.2rem;
                                padding: 0.12rem 0.2rem;
                                border: 0.02rem solid #DAB0FF;
                                margin-bottom: 0.02rem;
                                font-size: 0.14rem;
                            }
                        }
                    }
                }
            }
        }
    }
}